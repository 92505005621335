@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Password/password.ttf");
}

:root {
  --sans-font: "Lato", sans-serif;
  --mono-font: Consolas, source-code-pro, Menlo, Monaco, "Courier New",
    monospace;

  --base-size: 1rem;

  --size-100: calc(var(--base-size) * 0.512);
  --size-200: calc(var(--base-size) * 0.64);
  --size-300: calc(var(--base-size) * 0.8);
  --size-400: var(--base-size);
  --size-500: calc(var(--base-size) * 1.25);
  --size-600: calc(var(--base-size) * 1.563);
  --size-700: calc(var(--base-size) * 1.953);
  --size-800: calc(var(--base-size) * 2.441);
  --size-900: calc(var(--base-size) * 3.052);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--sans-font);
  background-color: #fffffa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
}
