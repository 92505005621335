@import "@aws-amplify/ui-react/styles.css";

.amplify-label {
  display: none;
}
.amplify-tabs {
}
.amplify-tabs-item[data-state="active"] {
  border-top-width: 5px;
}

:root {
  --amplify-fonts-default-variable: "Lato";
  --amplify-fonts-default-static: "Lato";
  --amplify-colors-brand-primary-10: var(--amplify-colors-green-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-green-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-green-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-green-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-green-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-green-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-green-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-primary: #fffffa;
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 0.5rem;
  --amplify-space-medium: 0.7rem;
  --amplify-space-large: 1rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;

  --amplify-fonts-default-variable: "Lato";
  --amplify-fonts-default-static: "Lato";
  --amplify-colors-brand-primary-10: var(--amplify-colors-green-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-green-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-green-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-green-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-green-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-green-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-green-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-primary: #fffffa;
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-space-small: 0.5rem;
  --amplify-space-medium: 0.5rem;
  --amplify-space-large: 0.5rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 2px;
  --amplify-border-widths-large: 2px;
}
